import React, { useState } from 'react'
import { Alert } from '@mui/material'
import { useSeadApi } from '../hooks/useSeadApi'
import { getRuntimeConfig } from '../util/Config'
import { useLoggedInUser } from '../hooks/useLoggedInUser'

const config = getRuntimeConfig()

/**
 * Displays alerts at the top of the app. Maintenance/outage alerts are
 * displayed in yellow to all users. Business announcements/banners are
 * displayed in blue and are pod specific.
 */
export default function AppAlerts() {
  const [bannerMsg, setBannerMsg] = useState<string | null>()
  const { getBannerMessage } = useSeadApi()
  const { user } = useLoggedInUser()

  React.useEffect(() => {
    /**
     * Fetch any banner message to display in the webapp
     */
    async function fetchBanner() {
      if (user) {
        const { message } = await getBannerMessage({ podId: user.pod })
        setBannerMsg(message)
      }
    }
    fetchBanner()
  }, [user])

  /**
   * An array of all alerts to be shown in the UI.
   * Only alerts with `visible: true` and a non-empy mesage will appear
   * @member {object[]} alerts
   */
  const alerts = [
      {
        name: 'maintenance',
        type: 'warning',
        message: config.MAINTENANCE_MESSAGE,
        visible: true,
      } as const,
      {
        name: 'banner',
        type: 'info',
        message: bannerMsg,
        visible: !!user,
      } as const,
  ]

  return (
    <>
      {alerts.map((alert) => (
        alert.visible && alert.message && (
        <Alert severity={alert.type} key={alert.name}>
          {alert.message}
        </Alert>
        )
      ))}
    </>
  )
}

