import React, {
  useContext, createContext,
  useState,
  useMemo,
} from 'react'
import { useMsal } from '@azure/msal-react'
import jwtDecode from 'jwt-decode'
import { getRuntimeConfig } from '../util/Config'
import { Role } from '../enums/Role'
import type { AccountInfo } from '@azure/msal-browser'

const config = getRuntimeConfig()

/**
 * Extends the MSAL user object with properties extracted from the access token
 */
export interface SeadAccountInfo extends AccountInfo {
  /** The roles the user is granted */
  roles: Role[]
  /** The SEAD Pod identifier the user is a member of */
  pod: string
}

const LoggedInUserContext = createContext({} as {
  /** The currently logged in user object */
  user?: SeadAccountInfo,
  /** Function for getting a new access token silently */
  getTokenSilent: () => Promise<string>
})

/** Props for the LoggedInUserProvider */
interface ProviderProps {
  /** Child components within the context of the provider context */
  children: React.ReactNode
}

/**
 * The LoggedInUserProvider provides access to the currently logged in user
 * account. It adds additional information to the base MSAL account such as the
 * user roles and podId inferred from the access token. It provides a
 * getTokenSilent method to acquire an access token for the logged in user,
 * which wraps the MSAL equivalent method with custom logic. The
 * LoggedInUserProvider must be within the context of the MsalProvider.
 * @param props - The provider props
 */
function LoggedInUserProvider(props: Readonly<ProviderProps>) {
  const { children } = props
  const { instance } = useMsal()
  const [user, setUser] = useState<SeadAccountInfo>()
  const account = instance.getActiveAccount()
  /**
   * Calls MSAL 2.x acquireTokenSilent and attempts to retrieve auth token silently from cache, or
   * acquire a new token using the refresh token. If app consent is required, a popup will show for
   * the user to consent.
   * @returns tokenResponse
   */
  const getTokenSilent = async (): Promise<string> => {
    try {
      if (!account) {
        await instance.loginRedirect()
        throw new Error('Account not present, redirecting to login')
      }
      const accessToken = await instance.acquireTokenSilent({
        scopes: [config.SCOPE_INVOKE],
        account,
      })
      return accessToken.accessToken
    } catch (error) {
      await instance.loginRedirect()
      throw error
    }
  }

  /**
   * Infers additional properties of the user from the access token and sets
   * user object state
   */
  async function inferUser() {
    if (!account) {
      setUser(undefined)
      return
    }
    const accessToken = await getTokenSilent()
    const decoded = jwtDecode<{
        /** The role claim contains an array of strings with the users granted roles i.e. ['analyst'] */
        roles: Role[],
        /** The department claim contains the users' pod identifier i.e. 'ABS' */
        department: string
      }>(accessToken)
    setUser({
      ...account,
      roles: decoded.roles,
      pod: window.localStorage.getItem('sysadmin.pod_override') ?? decoded.department,
    })
  }

  React.useEffect(() => {
    inferUser()
  }, [account?.homeAccountId])

  return (
    <LoggedInUserContext.Provider value={useMemo(() => ({ user, getTokenSilent }), [user])}>
      {children}
    </LoggedInUserContext.Provider>
  )
}

const useLoggedInUser = () => {
  const context = useContext(LoggedInUserContext)
  if (context === undefined) {
    throw new Error('useLoggedInUser must be used within a LoggedInUserProvider')
  }
  return context
}

export {
  LoggedInUserProvider,
  useLoggedInUser,
}
